import React from 'react';
import firebase from './config/firebase';
import logo from './logo.png'; 
import { Form, Icon, Input, Button, Col, Row, Alert} from 'antd';

class HorizontalLoginForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Isloading: false,
            msgErr: '',
        };
    }

    componentDidMount() {
        // To disable submit button at the beginning.
        this.props.form.validateFields();
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            this.setState({
                Isloading: true,
            });
            firebase.auth().signInWithEmailAndPassword(values.email, values.password)
            .then((u) => {
                console.debug(u);
            })
            .catch((err) => {
                console.error(err);
                this.setState({
                    Isloading: false,
                    msgErr: err.message,
                });
            });
            if (!err) {
                console.log('Received values of form:', values);
            }
        });
    };

    render() {
        const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = this.props.form;

        // Only show error after a field is touched.
        const emailError = isFieldTouched('email') && getFieldError('email');
        const passwordError = isFieldTouched('password') && getFieldError('password');
        return (
            <div className='login-container'>
                <Row>
                    <Col>
                        <img src={logo} alt="logo" />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form layout="inline" onSubmit={this.handleSubmit}>
                            <Form.Item validateStatus={emailError ? 'error' : ''} help={emailError || ''}>
                                {
                                    getFieldDecorator('email', {
                                        rules: [{
                                            type: 'email',
                                            required: true,
                                            message: 'Email appears invalid'
                                        }],
                                    })(
                                        <Input prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="email" />,
                                    )
                                }
                            </Form.Item>
                            <Form.Item validateStatus={passwordError ? 'error' : ''} help={passwordError || ''}>
                                {
                                    getFieldDecorator('password', {
                                        rules: [{
                                            required: true,
                                            message: 'Password is required'
                                        }],
                                })(
                                    <Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="password" />,
                                )}
                            </Form.Item>
                            <Form.Item>
                                <Button loading={this.state.Isloading} type="primary" htmlType="submit" disabled={hasErrors(getFieldsError())}>
                                    Log in
                                </Button>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
                <Row>
                    <Col style={{width: 400, margin: "0 auto"}}>
                        {this.state.msgErr && <Alert message={this.state.msgErr} type="error" /> }
                    </Col>
                </Row>
            </div>
        );
    }
}

function hasErrors(fieldsError) {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
}

const LoginForm = Form.create({ name: 'horizontal_login' })(HorizontalLoginForm);

export default LoginForm;
