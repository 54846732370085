import React, { Component } from 'react';
import axios from 'axios';
import { Row, Col, Table, Input, Checkbox, Layout, Button, Divider, Tag, Select, Alert, Modal} from 'antd';
import 'antd/dist/antd.css';
import { CSVLink } from "react-csv";
const { Search } = Input;
const { Content } = Layout;
const { Option } = Select;
const { CheckableTag } = Tag;

export default class DatatablePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            plainTextOnly:      false,
            loadingText:        '',
            whois:              '',
            dataSource:         {},
            errmsg:             '',
            dataLoading:        false,
            loading:            false,
            searchText:         '',
            showCsvLink:        false,
            csvLink:            null,
            method:             'usernames',
            moreMsg:            '',
            moreMsgVisible:     true,
            keyword:            '',
            hits:               null,
            cursor:             null,
            selectedTags: [
                'username',
                'email',
                'domain',
                'password_plaintext',
                'password',
            ],
            tags: [
                'username',
                'email',
                'domain',
                'password_plaintext',
                'password',
                'gender',
                'phone',
                'DOB',
                'account_signup_time',
                'city',
                'state',
                'country',
                'first_name',
                'last_name',
                'full_name',
                'isp',
                'password_type',
                'salt',
                'target_domain',
                'backup_email_username',
                'address_1',
                'backupusername',
                'social_skype',
                'social_facebook',
                'social_steam',
                'social_twitter',
                'social_instagram',
                'social_other',
                'source_id',
            ],
        };
    }

    kFormatter(num) {
        return Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'k' : Math.sign(num) * Math.abs(num);
    }

    handleChange = (pagination, filters, sorter) => {
        console.log('%csort', 'color: red; font-weight: bold', sorter);
        // this.setState({
        //     sortedInfo: sorter,
        // });
    };

    onTogglePlainTextOnly = (e) => {
        this.setState({
            plainTextOnly: e.target.checked,
        });
    }

    clearAll = () => {
        // this.setState({
        //     sortedInfo: null,
        // });
    };

    downloadCsv = () => {
        this.state.csvLink.link.click();
    }

    getFilteredDataSorce = () => {
        return (this.state.plainTextOnly) ? this.state.dataSource.filter((row) => row.password_plaintext && row.password_plaintext != 'n/a' && row.password_plaintext != '') : JSON.parse(JSON.stringify(this.state.dataSource));
    }

    getFilteredColumns = () => {
        // generate columns array from selected tags
        return this.state.selectedTags.map((tag) => {
            return {
                title:      tag,
                dataIndex:  tag,
                sorter:     (a, b) => {
                    return ((a[tag]) ? (a[tag] + '').toLowerCase() : 'Ω') < ((b[tag]) ? (b[tag] + '').toLowerCase() : 'Ω') ? -1 : (((a[tag]) ? (a[tag] + '').toLowerCase() : 'Ω') > ((b[tag]) ? (b[tag] + '').toLowerCase() : 'Ω') ? 1 : 0);
                },
                ellipsis:   true,
            };
        });
    }

    async onSearch(val, getNextBatch) {
        console.log('onSearch()', {val: val, getNextBatch: getNextBatch});
        this.clearAll();
        const self = this;
        if (!getNextBatch) {
            this.setState({
                loading: true,
                keyword: val,
                dataSource: {},
                dataLoading: true,
                errmsg: '',
                loadingText: 'Loading ...',
            });
        } else {
            this.setState({
                loading: true,
                dataLoading: true,
                errmsg: '',
                loadingText: 'Loading ...',
            });
        }

        await axios.post('https://xkeyscore.com/api/all', {
            method: this.state.method,
            keyword: val,
            cursor: (this.state.cursor ? this.state.cursor : ''),
        })
        .then((res) => {
            let Fetched_data = res.data.results;
            let hits = res.data.hits;
            let cursor = res.data.cursor;
            this.setState({
                hits,
                cursor
            });
            for (let index = 0; index < Fetched_data.length; index++) {
                for (let indexTag = 0; indexTag < self.state.tags.length; indexTag++) {
                    if (!Fetched_data[index][self.state.tags[indexTag]] || !String(Fetched_data[index][self.state.tags[indexTag]])) {
                        Fetched_data[index][self.state.tags[indexTag]] = '';
                    } else {
                        String(Fetched_data[index][self.state.tags[indexTag]]);
                    }
                }

                if (Fetched_data[index].document_id) {
                    delete Fetched_data[index].document_id;
                    delete Fetched_data[index].spycloud_publish_date;
                }
            }

            if (!this.state.dataSource.length) {
                this.setState({
                    dataSource: Fetched_data,
                    showCsvLink: true,
                    dataLoading: false,
                    loadingText: '',
                    errmsg: ''
                });
            } else {
                this.setState({
                    dataSource: [...this.state.dataSource, ...Fetched_data],
                    showCsvLink: true,
                    dataLoading: false,
                    loadingText: '',
                    errmsg: ''
                });
            }
        })
        .catch((error) => {
            self.setState({
                dataLoading: false,
                errmsg: 'Something went wrong',
                loadingText: '',
            });
            console.error(error);
        });
    }

    // onChangeLoad(event) {
    //     console.debug(event);
    // }
 
    handleChangeTag(tag, checked) {
        console.log('handleChangeTag', tag);
        this.setState({
            selectedTags: checked ? [...this.state.selectedTags, tag] : this.state.selectedTags.filter((t) => t !== tag),
        });
    }

    handleOk = (e) => {
        this.setState({
            moreMsgVisible: false,
        });
    };

    handleCancel = (e) => {
        this.setState({
            moreMsgVisible: false,
        });
    };

    render() {
        function onChangeSelect(method) {
            if (method !== this.state.method) {
                this.setState({
                    method: method,
                    cursor: null,
                    dataSource:{}
                });
            } else {
                this.setState({
                    method
                });
            }
        }

        function onBlur() {
            console.log('blur');
        }

        function onFocus() {
            console.log('focus');
        }

        return (
            <Content className='datatable-container'>
                <Row>
                    <Divider orientation="left" orientationMargin={20} className='noselect'>Search Criteria</Divider>
                    <Col span={6}>
                        <Select
                            style={{ width: '100%' }}
                            defaultValue='usernames'
                            size="medium"
                            placeholder="Search method"
                            optionFilterProp="children"
                            onChange={onChangeSelect.bind(this)}
                            onFocus={onFocus}
                            onBlur={onBlur}
                            filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            <Option value="emails">Emails</Option>
                            <Option value="ips">IP</Option>
                            <Option value="usernames" selected>Username</Option>
                            <Option value="phone-numbers">Phone numbers</Option>
                            <Option value="domains">Domains</Option>
                        </Select>
                    </Col>
                    <Col span={18}>
                        <Search
                            loading={this.state.dataLoading}
                            placeholder="Search string"
                            enterButton={this.state.loadingText || 'Search'}
                            size="medium"
                            onChange={(val) => {
                                // console.log(val.target.value);
                                this.setState({
                                    dataSource: {},
                                    cursor: null
                                });
                                // let value = val.target.value;
                                // setTimeout(() => {
                                //     this.onSearch(value);
                                // }, 500);
                            }}
                            onSearch={(val) => this.onSearch(val)}
                        />
                    </Col>
                </Row>
                {this.state.errmsg &&
                    <Alert message={this.state.errmsg} type="error" />
                }
                {this.state.moreMsg &&
                    <Modal
                        title={this.state.whois}
                        visible={this.state.moreMsgVisible}
                        onOk={this.handleOk}
                        onCancel={this.handleCancel}
                        okText='Sounds good'
                        mask={true}
                        footer={null}
                        width='50%'
                        className='modal-json'
                    >
                        {JSON.stringify(this.state.moreMsg, null, 4)}
                    </Modal>
                }
                <Divider orientation="left" orientationMargin={20} className='noselect'>Columns</Divider>
                <div style={{lineHeight: 2.1}}>
                    {this.state.tags.map(tag => (
                        <CheckableTag
                            key={tag}
                            checked={this.state.selectedTags.includes(tag)}
                            onChange={(checked) => this.handleChangeTag(tag, checked)}
                            className='noselect'
                        >
                            {tag}
                        </CheckableTag>
                    ))}
                </div>
                <Divider orientation="left" orientationMargin={20} className='noselect'>Settings</Divider>
                <div style={{ fontSize: '12px'}} className='noselect'>
                    <Checkbox onChange={this.onTogglePlainTextOnly}>Plain Text passwords only</Checkbox>
                </div>
                <Divider />
                <Table 
                    onChange={this.handleChange}
                    filtered={true}
                    pagination={{showQuickJumper: true, defaultPageSize: 100}}
                    onRow={(record, rowIndex) => {
                        return {
                            onClick: (event) => {
                                this.setState({
                                    moreMsg: record,
                                    whois: record.username,
                                    moreMsgVisible: true
                                }
                            )},
                        };
                    }}
                    loading={this.state.dataLoading}
                    dataSource={this.state.dataSource.length >=1 && this.getFilteredDataSorce()}
                    columns={this.getFilteredColumns()}
                />
                <Divider />
                <div className='csv-button-container'>
                    {this.state.dataSource.length >= 1 &&
                        <CSVLink
                            filename={"API_results.csv"}
                            data={this.getFilteredDataSorce()}
                            className="hidden"
                            ref={(ref) => (this.state.csvLink = ref)}
                            target="_blank"
                        />
                    }
                    {this.state.dataSource.length >= 1 &&
                        <Button
                            size="large"
                            style={{ marginRight: 4, marginLeft: 4}}
                            icon="cloud-download"
                            type="primary"
                            disabled={this.state.dataLoading}
                            onClick={()=> this.downloadCsv()}
                        >
                            Download {this.kFormatter(this.getFilteredDataSorce().length)} record(s) as CSV
                        </Button>
                    }
                    {this.state.dataSource.length >=1000 && 
                        <Button
                            onClick={()=> this.onSearch(this.state.keyword, true)}
                            type="default"
                            size="large"
                            style={{ marginRight: 4, marginLeft: 4}}
                            loading={this.state.dataLoading}
                            icon="sync"
                            disabled={this.state.dataSource.length >= this.state.hits}
                        >
                            Fetch next 1k results ({this.kFormatter(this.state.dataSource.length) + ' of ' + this.kFormatter(this.state.hits)})
                        </Button>
                    }
                </div>
            </Content>
        );
    }
}

