import React, { Component } from 'react';
import firebase from './config/firebase';
import { Layout} from 'antd';
import LoginForm from './Login';
import Index from './indexC';
import './App.css';

const Wait = () => {
    return <div className='page-loading-container'>Loading...</div>;
}

export default class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: null
        }
    }

    componentDidMount() {
        this.authListener();
    }

    authListener() {
        firebase.auth().onAuthStateChanged((user) => {
            // console.debug(user);
            if (user) {
                this.setState({
                    user,
                });
            } else {
                this.setState({
                    user: false,
                });
            }
        });
    }

    render() {
        if (this.state.user === null) {
            return (
                <Wait />
            );
        }
        return (
            <div className="App">
                <Layout>
                    {this.state.user ? (<Index />) : (<LoginForm />)}
                </Layout>
            </div>
        );
    }
}



