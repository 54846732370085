import React, { Component } from 'react';
import DatatablePage from './DatatablePage';
// import AddNewUser from './users';
import firebase from './config/firebase';
// import { Tabs, Icon, Avatar, Button } from 'antd';
import { Icon } from 'antd';
// const { TabPane } = Tabs;
// import logo from './logo.png'; 

export default class Index extends Component {
    constructor() {
        super();
        this.state = {
            Isloading : false
        };
    }

    callback(key) {
        console.log(key);
    }

    signOutUser() {
        const self = this;
        self.setState({
            Isloading: true,
        });

        firebase.auth().signOut()
        .then(() => {
            self.setState({
                Isloading: false,
            });
        })
        .catch((error) => {
            self.setState({
                Isloading: false,
            });
            console.error(error);
        });
    }

    render() {
        return (
            <div>
                <div className='nav'>
                    <div className='title'>
                        XKeyscore
                    </div>
                    <div className='user'>
                        <span onClick={() => this.signOutUser()} className='clickable logout' title='Logout'>
                            <Icon type="lock" className='logout-icon' />
                            Kevin
                        </span>
                    </div>
                </div>
                <DatatablePage />
            </div>
        );
    }
}




