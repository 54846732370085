// import * as firebase from 'firebase';
// // import firebase from 'firebase/app';

const firebase = require('firebase/app');
require('firebase/auth');

const firebaseConfig = {
    apiKey:             'AIzaSyDPcx2a-mrf_SwvCazVvty-OAe1FfC1hbU',
    authDomain:         'xkeyscore-d5cb3.firebaseapp.com',
    databaseURL:        'https://xkeyscore-d5cb3.firebaseio.com',
    projectId:          'xkeyscore-d5cb3',
    storageBucket:      'xkeyscore-d5cb3.appspot.com',
    messagingSenderId:  '640244503675',
    appId:              '1:640244503675:web:81d204c45b514d73b2fede',
};

firebase.initializeApp(firebaseConfig);
export default firebase;
